import { useLanguage } from '@/hooks/Contexts'
import { COUNTRY_LIST } from '@/utils/Consts'
import { CountryCode, getExampleNumber, PhoneNumber } from 'libphonenumber-js'
import examples from 'libphonenumber-js/mobile/examples'
import { FC, InputHTMLAttributes, useEffect, useMemo, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import 'react-phone-number-input/style.css'

export const PhoneNumberInput: FC<{
  isAddress: boolean
  form: UseFormReturn
  extraCls?: string
  numberInputProps?: InputHTMLAttributes<HTMLInputElement>
}> = ({ isAddress, form, extraCls, numberInputProps }) => {
  const { user: userStr, errors: errStr } = useLanguage()
  const {
    control,
    formState: { errors },
    getValues,
  } = form
  const [example, setExample] = useState<PhoneNumber>()
  const defaultCountry = useMemo<CountryCode>(
    () => COUNTRY_LIST.find(x => x.name == getValues('address.country'))?.code ?? 'GB',
    [getValues('address.country')]
  )
  useEffect(() => {
    if (!example && defaultCountry) {
      const exp = getExampleNumber(defaultCountry, examples)
      setExample(exp)
    }
  }, [])
  const errMsg = useMemo(
    () => (isAddress ? errors?.['address']?.['phone']?.['message'] : errors?.['phone_num']?.['message']),
    [errors?.['phone_num'], errors?.['address']?.['phone']]
  )
  return (
    <div className={`relative ${extraCls || ''}`}>
      <label className='font-medium text-black'>
        {userStr.phoneNum}
        <span className='ml-1 text-error'>*</span>
      </label>
      <PhoneInputWithCountry
        name={isAddress ? 'address.phone' : 'phone_num'}
        control={control}
        rules={{
          required: userStr.phoneNum + errStr.required,
          validate: v => isValidPhoneNumber(v) || errStr.phone_invalid,
        }}
        numberInputProps={numberInputProps}
        countryOptionsOrder={['GB', 'IE', '|', '...']}
        onCountryChange={country => {
          const exp = country ? getExampleNumber(country, examples) : null
          setExample(exp)
        }}
        placeholder={`+${example?.countryCallingCode ?? 'Area Code'}${
          example?.formatInternational?.() ? ' (Example ' + example.formatInternational() + ')' : ''
        }`}
        defaultCountry={defaultCountry}
        countryCallingCodeEditable={false}
        className={`mt-2 flex items-center rounded border-2 bg-white/100 px-2 py-2 shadow-lg shadow-stone-200 transition md:px-4 ${
          errMsg ? '!border-error !text-error' : '!border-stone-200 !text-neutral-600 hover:!border-cyan-v2 focus:!border-cyan-v2'
        }`}
      />
      {errMsg ? <div className='mt-1 text-xs text-error'>{errMsg}</div> : null}
    </div>
  )
}

export default PhoneNumberInput
