import { FC, HTMLAttributes, ReactNode, useEffect, useState } from 'react'
import CaretIcon from '../CaretIcon'

export const ExpandableBlock: FC<{
  title: ReactNode
  titleBtn?: ReactNode
  titleCls?: string
  descr?: ReactNode
  toggleProps?: HTMLAttributes<HTMLDivElement>
  outerCls?: string
  innerCls?: string
  notExpandable?: boolean
  openDefault?: boolean
  close?: void
  hideOnly?: boolean
}> = ({ title, titleBtn, titleCls, descr, toggleProps, outerCls, innerCls, children, notExpandable, openDefault, hideOnly }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (openDefault) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [openDefault])

  if (notExpandable) {
    return (
      <div className={`card-container px-4 pb-1.5 ${outerCls || ''}`}>
        <span className='block py-5 text-sm font-semibold'>{title}</span>
        {children}
      </div>
    )
  }

  const toggleIsOpen = titleBtn ? () => setIsOpen(prev => !prev) : null

  return (
    <div className={`card-container px-2 md:px-4 ${isOpen ? 'pb-4' : 'pb-0'} ${outerCls || ''}`}>
      <div {...toggleProps} className='cursor-pointer py-4' onClick={titleBtn ? null : () => setIsOpen(prev => (prev ? false : true))}>
        <div className={`flex items-center ${titleCls || ''}`}>
          <div className='flex-1 font-semibold' onClick={toggleIsOpen}>
            {title}
          </div>
          {titleBtn}
          <CaretIcon contentOpen={isOpen} onClick={toggleIsOpen} />
        </div>
        {descr && (
          <div className='break-all text-xs' onClick={toggleIsOpen}>
            {descr}
          </div>
        )}
      </div>
      {hideOnly ? (
        <div className={`animate-fade-in-down${isOpen ? '' : ' hidden'} ${innerCls || ''}`}>{children}</div>
      ) : (
        isOpen && <div className={`animate-fade-in-down ${innerCls || ''}`}>{children}</div>
      )}
    </div>
  )
}
export default ExpandableBlock
